import React from 'react';

const Contributors = () => {

  return(

    <div className="pt-5">
      <h4>Project contributors:</h4>
      <ul className="text-muted small">
        <li>Gairn McLennan</li>
        <li>Adrien Baltardive</li>
      </ul>
    </div>

  );

}

export default Contributors;
